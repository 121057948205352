import React from "react";

import wellnessmarch from "../../assets/images/retreats/wellness-weekend-march-19.jpg";
import { Link } from "gatsby";

export default function Retreatssec() {
  return (
    <section className="retreats-sec-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper theme-color"> Retreats </h3>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
          <img
              className="img-fluid mg-bottom20"
              src={wellnessmarch}
              alt="Wellness March"
            />
              <h4> Wellness Weekend</h4>
              <h4> 22nd to 24th March 2019 </h4>
              <p className="theme-p-tag"> Kanha Shanti Vanam – Hyderabad – India </p>
              <p className="theme-p-tag"> Arrive on Friday night, sleep early to wake up at dawn for a gentle yoga class, meditate, return to nature, learn polarity – your heart is in the right place! Experience the beauty of Kanha Shanti Vanam.</p>
              <p> <Link class="link-color font-book" to="https://kanhareg.sahajmarg.org/reg/#/kanha/events/retreat/c5d4937b-3177-4329-8b5b-6b6478e5be4b" target="_blank" rel="noopener noreferrer">Read More</Link></p>
          </div>
        </div>
      </div>
    </section>
  );
}

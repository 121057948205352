import React from "react";

import { Link } from "gatsby";


export default function Retreatabout() {
  return (
    <section className="retreatabout-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper theme-color"> About Us</h3>
            <p className="theme-p-tag pad-top20-safari">
              {" "}
              The Heartfulness Institute offers specialized programs and
              retreats, which teaches heart-based meditation with Yogic
              transmission to people in more than 110 countries around the
              world. There are 8,000+ trainers, many of whom are working with
              corporates, government departments and businesses, as well as
              doctors working in hospitals, research institutes and
              universities.
            </p>
            <p className="theme-p-tag">
              {" "}
              The Heartfulness Institute is the umbrella organization for all
              Heartfulness programs including the Continuing Medical Education
              Certification (15 CME credits – accreditation by American medical
              association) for healthcare professionals. Heartfulness is a
              partner of the American Heart Association.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              To this day, more than 2 Million people have tried Heartfulness
              meditation.{" "}
            </p>
            <div className="cent-item">
            <h5> Contact us</h5>
            <p class="theme-p-tag">For further information email us at <Link className="link-color" to="mailto:in.wellness@heartfulness.org" target="_blank" rel="noopener noreferrer">in.wellness@heartfulness.org</Link></p>       
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

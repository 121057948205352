import React from "react";

import wellnessretreatbanner from "../../assets/images/retreats/wellness-retreat-new.jpg";

import wellnesslogo from "../../assets/images/retreats/heartfulness-wellness-logo.png";

export default function Wellnessbanner() {
  return (
    <section className="wellness-banner-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 bg-banner-text">
            <img
              className="img-fluid"
              src={wellnessretreatbanner}
              alt="Wellness banner"
            />
            <div className="caption-bg-text text-white">
              <h1> Heartfulness </h1>
              <h4> Wellness Retreats</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container pad60">
        <div className="row">
          <div className="col-md-12 cent-item">
          <img
              className="img-fluid width-450"
              src={wellnesslogo}
              alt="Wellness Logo"
            />
              <p className="theme-p-tag">  To be truly holistic, one has to take care of their existence at all levels:</p>
              <p className="theme-p-tag"> this is where Heartfulness makes a difference – taking care of the Body, Mind and Spirit with transformative retreats allowing longstanding change.</p>
              <p className="theme-p-tag"> Discover purity and simplicity, adopt positivity, and let your life be led through your heart.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Wellnessbreadcreumb from "../components/retreats/wellness-breadcumb";

import Wellnessbanner from "../components/retreats/wellness-banner";

import Retreatssec from "../components/retreats/retreats-sec";

import Retreatabout from "../components/retreats/retreat-about";

// markup
const WellnessretreatPage = () => {
  return (
    <div>
      <Seo title="Wellness Retreats" />
      <Layout isSticky>
        <section>
          <Wellnessbreadcreumb />
        </section>
        <section>
          <Wellnessbanner />
        </section>
        <section>
          <Retreatssec />
        </section>
        <section>
          <Retreatabout />
        </section>
      </Layout>
    </div>
  );
};

export default WellnessretreatPage;
